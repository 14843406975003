import ReactDOM from 'react-dom/client';
import './assets/css/index.css';
import { lazy, Suspense } from 'react';
import { loadScripts } from '@features/load-scripts';

/* develop for fast past in landing
  const script = document.createElement('script');
  script.src = 'http://localhost:8080/static/js/main.e0969efa.js';
  document.head.appendChild(script);
*/

function renderApp() {
  const App = lazy(() => import('./app'));
  const root = ReactDOM.createRoot(
    document.getElementById('pulseRoot') as HTMLElement
  );
  root.render(
    <Suspense fallback={null}>
      <App />
    </Suspense>
  );
}

const host = (window as any).host || '';
const requiredScripts = [
  'https://content.adriver.ru/AdRiverFPS.js',
  `${host}/js/adriver.js`,
  `${host}/js/env-keys.js`,
  `${host}/js/my-tracker.js`,
  `${host}/js/env-urls.js`,
];

loadScripts(requiredScripts, () => renderApp());
