const loadScriptSequentially = (
  scripts: string[],
  index = 0,
  cb = () => {}
) => {
  if (index >= scripts.length) {
    // Все скрипты загружены, выполняем cb
    cb();
    return;
  }

  const scriptUrl = scripts[index];
  const script = document.createElement('script');

  script.src = scriptUrl;
  script.addEventListener('load', () => {
    // Загружаем следующий скрипт после загрузки текущего
    loadScriptSequentially(scripts, index + 1, cb);
  });

  // Обработка ошибки загрузки скрипта
  script.addEventListener('error', () => {
    console.error(`Failed to load script: ${scriptUrl}`);
    // стопаем загрузку скриптов
    // loadScriptSequentially(scripts, index + 1, cb);
  });

  document.head.appendChild(script);
};

export const loadScripts = (scripts: string[], cb = () => {}) => {
  loadScriptSequentially(scripts, 0, cb);
};
